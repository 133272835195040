<template>
  <AbstractDataTable
    :headers="tableHeaders"
    :items="quotes"
    :total-count="totalCount"
    :current-page="currentPage"
    :on-page-change="onPageChange"
    store-name="quote"
    table-css-class="quote-table"
    >
    <template #tableBody="{ item }">
      <TitleColumn
        :title-header="tableHeaders[0].name"
        :title-value="item.id"
        subtitle
      />
      <TitleColumn
        :title-header="tableHeaders[1].name"
        :title-value="item.title"
      />
      <TitleColumn
        :title-header="tableHeaders[2].name"
        :title-value="item.publishedSince ? $options.filters.prettyDateTime(item.publishedSince) : ''"
      />
      <CreatedByAndAtColumn
        :created-at="item.createdAt"
        :created-by="item.createdBy"
      />
    </template>
  </AbstractDataTable>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import CreatedByAndAtColumn from '@/components/table/columns/CreatedByAndAtColumn'

export default {
  name: 'QuoteTable',
  props: {
    quotes: {
      type: Array,
      required: true
    },
    totalCount: {
      type: [Number, String],
      default: 0
    },
    currentPage: {
      type: Number,
      required: true
    },
    onPageChange: {
      type: Function,
      required: true
    }
  },
  components: {
    CreatedByAndAtColumn,
    TitleColumn,
    AbstractDataTable
  },
  data () {
    return {
      tableHeaders: [
        { name: this.$t('quote.list.id') },
        { name: this.$t('quote.list.title') },
        { name: this.$t('quote.list.published_since') },
        {
          name: this.$t('system.created_at'),
          subHeader: this.$t('system.created_by')
        },
        { name: this.$t('actions') }
      ]
    }
  }
}
</script>
<style lang="scss">
  .quote-table {
    &__thead,
    &__tr {
      @include bp(12) {
        grid-template-columns: rem(50px) auto rem(130px) rem(130px) max-content;
      }
      @include bp(14) {
        grid-template-columns: rem(50px) auto rem(160px) rem(160px) rem(138px);
      }
    }
  }
</style>
